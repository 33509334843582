<template>
    <div class="password_container">
        <p v-bind:class="{ first: first, 'all_true': (first && second && third && fourth) }" style="background: linear-gradient(to right, #ff3b3b 50%, #eee 50%);background-size: 201% 100%;background-position: right;"></p>
        <p v-bind:class="{ second: second, 'all_true': (first && second && third && fourth) }" style="background: linear-gradient(to right, #f5a623 50%, #eee 50%);background-size: 201% 100%;background-position: right;"></p>
        <p v-bind:class="{ third: third, 'all_true': (first && second && third && fourth) }" style="background: linear-gradient(to right, #f5a623 50%, #eee 50%);background-size: 201% 100%;background-position: right;"></p>
        <p v-bind:class="{ fourth: fourth, 'all_true': (first && second && third && fourth) }" style="background: linear-gradient(to right, #00AD7C 50%, #eee 50%);background-size: 201% 100%;background-position: right;" class="mr-0"></p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            first: false,
            second: false,
            third: false,
            fourth: false,
        }
    },
    props: ["pwd"],
    watch: {
        pwd: function() {
            if (this.pwd) {
                if (/^(?=.*[A-Z])/.test(this.pwd) || /^(?=.*[0-9])/.test(this.pwd) || /^(?=.*[!@#$%^&*()])/.test(this.pwd) || /^(?=.{8,}$)/.test(this.pwd)) {
                    this.first = true;
                    this.second = false;
                    this.third = false;
                    this.fourth = false;
                }
                if (/^(?=.*[A-Z])/.test(this.pwd) && /^(?=.*[0-9])/.test(this.pwd) || /^(?=.*[!@#$%^&*()])/.test(this.pwd) || /^(?=.{8,}$)/.test(this.pwd)) {
                    this.first = true;
                    this.second = true;
                    this.third = false;
                    this.fourth = false;
                }
                if (/^(?=.*[A-Z])/.test(this.pwd) && /^(?=.*[0-9])/.test(this.pwd) && /^(?=.*[!@#$%^&*()])/.test(this.pwd) || /^(?=.{8,}$)/.test(this.pwd)) {
                    this.first = true;
                    this.second = true;
                    this.third = true;
                    this.fourth = false;
                }
                if (/^(?=.*[A-Z])/.test(this.pwd) && /^(?=.*[0-9])/.test(this.pwd) && /^(?=.*[!@#$%^&*()])/.test(this.pwd) && /^(?=.{8,}$)/.test(this.pwd)) {
                    this.first = true;
                    this.second = true;
                    this.third = true;
                    this.fourth = true;
                }
            } else {
                this.first = false;
                this.second = false;
                this.third = false;
                this.fourth = false;
            }
        } 
    }
}
</script>

<style scoped>
    .password_container {
        margin: 10px auto;
        display: flex;
    }

    .password_container p {
        width: 100%;
        height: 6px;
        margin: 0px 10px 0px 0px;
        border-radius: 2px;
        -webkit-transition: background .3s;
        transition: background .3s;
    }

    .first,
    .second,
    .third,
    .fourth {
        background-position: left !important;
    }
    .all_true {
        background: linear-gradient(to right, #00AD7C 50%, #eee 50%) !important;
        background-size: 201% 100% !important;
        background-position: inherit !important;
    }
</style>